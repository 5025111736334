import { cssTips } from "./utils/csstips_data";
import { htmlTagTips } from "./utils/htmltips_data";
import { jsAlgorithms } from "./utils/jsalgo_data";
import { jsTipsData } from "./utils/jstips_data";
import { leetcodeData } from "./utils/leetcode_data";
import { reactTipsData } from "./utils/reacttips_data";

const logotext = "ASWIN";
const meta = {
  title: "Aswin C",
  description:
    "I’m Aswin Javascript/UI devloper,currently working in Bangalore",
};

const introdata = {
  title: "I’m Aswin C",
  animated: [
    "React Developer",
    "React Native Developer",
    "Javascript Developer",
    "Front-end Developer",
  ],
  description:
    "Experienced developer proficient in JavaScript, React Native, React, VBCS, Node.js etc..",
  your_img_url: "https://images.unsplash.com/photo-1514790193030-c89d266d5a9d",
};

const dataabout = {
  title: "A bit about myself",
  aboutme:
    "Hey there! I'm an Engineer at Walmart, specializing in web development. Prior experience includes roles at Oracle, Infosys and Bititude Technologies, working with technologies like React, React Native, JavaScript, Firebase, and Node.js",
};

const worktimeline = [
  {
    jobtitle: "Software Developer III",
    where: "Walmart Global Tech India",
    date: "July 2024 - Present",
    img: "https://www.allrecipes.com/thmb/V0uJ5-OXuEY2ozGrx16E-QOw53I=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/ar-new-walmart-logo-spark-blue-4x3-05716be5a80a49858b751defdd5c3705.jpg",
    url: "https://tech.walmart.com/content/walmart-global-tech/en_us.html",
  },
  {
    jobtitle: "Senior Application Engineer",
    where: "Oracle",
    date: "Sep 2021 - June 2024",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Oracle_Corporation_logo.svg/2560px-Oracle_Corporation_logo.svg.png",
    url: "https://www.oracle.com",
  },
  {
    jobtitle: "Senior System Engineer",
    where: "Infosys",
    date: "Aug 2020 - Sep 2021",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Infosys_logo.svg/1280px-Infosys_logo.svg.png",
    url: "https://www.infosys.com",
  },
  {
    jobtitle: "Mobile | Web developer",
    where: "Bititude Technologies",
    date: "Aug 2018 - Jul 2020",
    img: "https://bititude.com/images/bit_logo.svg",
    url: "https://www.bititude.com",
  },
];

const oldworktimeline = [
  {
    jobtitle: "Senior Application Engineer",
    where: "Oracle",
    date: "Sep 2021 - Present",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Oracle_Corporation_logo.svg/2560px-Oracle_Corporation_logo.svg.png",
    url: "https://www.oracle.com",
  },
  {
    jobtitle: "Senior System Engineer",
    where: "Infosys",
    date: "Aug 2020 - Sep 2021",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Infosys_logo.svg/1280px-Infosys_logo.svg.png",
    url: "https://www.infosys.com",
  },
  {
    jobtitle: "Mobile | Web developer",
    where: "Bititude Technologies",
    date: "Aug 2018 - Jul 2020",
    img: "https://bititude.com/images/bit_logo.svg",
    url: "https://www.bititude.com",
  },
];

const skills = [
  { name: "JavaScript", logo: "javascript", main: true },
  { name: "TypeScript", logo: "ts", main: true },
  { name: "React", logo: "react", main: true },
  { name: "React Native", logo: "react", main: false },
  { name: "Redux", logo: "redux", main: false },
  { name: "HTML", logo: "html", main: true },
  { name: "CSS", logo: "css", main: true },
  { name: "Next.js", logo: "nextjs", main: false },
  { name: "Styled Components", logo: "styledcomponents", main: false },
  { name: "Tailwind CSS", logo: "tailwind", main: false },
  { name: "Bootstrap", logo: "bootstrap", main: false },
  { name: "Material UI", logo: "materialui", main: false },
  { name: "Angular", logo: "angular", main: false },
  { name: "Firebase", logo: "firebase", main: false },
  { name: "Node.js", logo: "nodejs", main: false },
  { name: "Express", logo: "express", main: false },
  { name: "PostgreSQL", logo: "postgres", main: false },
  { name: "Git", logo: "git", main: false },
  { name: "GitHub", logo: "github", main: false },
  { name: "GitHub Actions", logo: "githubactions", main: false },
  { name: "Grafana", logo: "grafana", main: false },
  { name: "Postman", logo: "postman", main: false },
  { name: "VSCode", logo: "vscode", main: false },
  { name: "Figma", logo: "figma", main: false },
];

// const skills = [
//   {
//     name: "Javascript",
//     value: 90,
//     logo: require("./assets/skills/js.png"),
//     main: true,
//   },
//   {
//     name: "React",
//     value: 85,
//     logo: require("./assets/skills/react.png"),
//     main: true,
//   },
//   {
//     name: "React Native",
//     value: 85,
//     logo: require("./assets/skills/react.png"),
//     main: false,
//   },
//   {
//     name: "Html",
//     value: 90,
//     logo: require("./assets/skills/html.png"),
//     main: true,
//   },
//   {
//     name: "Css",
//     value: 85,
//     logo: require("./assets/skills/css.png"),
//     main: true,
//   },
//   {
//     name: "Firebase",
//     value: 80,
//     logo: require("./assets/skills/firebase.png"),
//     main: false,
//   },
//   {
//     name: "Node.Js",
//     value: 75,
//     logo: require("./assets/skills/node.png"),
//     main: false,
//   },
// ];

const services = [
  // {
  //     title: "UI & UX Design",
  //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
  // },
  // {
  //     title: "Mobile Apps",
  //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
  // },
  // {
  //     title: "Wordpress Design",
  //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
  // },
];

const dataportfolio = [
  {
    img: require("./assets/apps/reactnative2.webp"),
    description: "React Native Animations",
    link: "https://react-native-ui-animations.web.app/",
  },
  {
    img: require("./assets/apps/djlight1.webp"),
    description: "Dj Light - Color Effects",
    link: "https://play.google.com/store/apps/details?id=com.aswin.djlight",
  },
  {
    img: require("./assets/apps/colorsplash1.webp"),
    description: "Color Splash | RN Game",
    link: "https://play.google.com/store/apps/details?id=com.colorsplash",
  },
  {
    img: require("./assets/apps/tictac1.webp"),
    description: "Tic Tac Toe | 1-10 Multiplayer",
    link: "https://play.google.com/store/apps/details?id=com.aswin.tictactoe",
  },
  {
    img: require("./assets/apps/colorlogic.webp"),
    description: "Color Logic",
    link: "https://play.google.com/store/apps/details?id=com.colorlogic",
  },
  //   {
  //     img: 'https://play-lh.googleusercontent.com/__UgZMPbUHIKvd_r37TQgZqDATi3CmnEjWD0kQvE0BhGDuHQ-65YOFJL3VzLSHW1aw=s360-rw',
  //     description:
  //       "",
  //     link: 'https://play.google.com/store/apps/details?id=com.colorlogic'
  //   },
  //   {
  //     img: "https://play-lh.googleusercontent.com/27LAkBawcI9lQijC_lqqZnZgA_abAbOz2H4KshE-QyT5kt65UvzOFgesV0qFEpsUxTg=s360-rw",
  //     description:
  //       "",
  //     link: 'https://play.google.com/store/apps/details?id=com.rntodo.aswin'
  //   },
];

const contactConfig = {
  YOUR_EMAIL: "aswinc605@gmail.com",
  // YOUR_FONE: "+91 8848014738",
  description:
    "Although I'm not currently looking for any new opportunities, my inbox is always open. Whether you have a question or just want to say hi, I'll try my best to get back to you!",
  // creat an emailjs.com account
  // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: "service_id",
  YOUR_TEMPLATE_ID: "template_id",
  YOUR_USER_ID: "user_id",
};

const socialprofils = {
  github: "https://github.com/aswincheriyala",
  // facebook: "https://facebook.com/aswinsree.c.5",
  linkedin: "https://linkedin.com/in/aswincheriyala",
  // twitter: "https://twitter.com/aswinc_",
};

const notes = [
  {
    id: 0,
    route: "leetcode",
    title: "Leetcode Problems",
    description:
      "Elevate your coding expertise with our curated collection of LeetCode challenges and solutions. Master algorithms, data structures, and problem-solving strategies to excel in technical interviews and real-world programming scenarios.",
    data: leetcodeData,
  },
  {
    id: 3,
    route: "javascript-algo",
    title: "JavaScript Algorithms",
    description:
      "Enhance your JavaScript skills with these popular algorithms. From searching and sorting to graph traversal and dynamic programming, these algorithms will help you solve a wide range of problems efficiently.",
    data: jsAlgorithms,
  },
  {
    id: 1,
    route: "javascript",
    title: "JavaScript",
    description:
      "Take your JavaScript skills to the next level with these advanced tips and best practices. Learn about modern syntax, performance optimization, asynchronous programming, and more to write efficient and robust JavaScript code.",
    data: jsTipsData,
  },
  {
    id: 2,
    route: "react",
    title: "React",
    description:
      "Improve your React skills with these advanced tips and best practices. Learn about state management, performance optimization, hooks, and more to build powerful and efficient React applications.",
    data: reactTipsData,
  },
  {
    id: 4,
    route: "html",
    title: "HTML Tags",
    description:
      "Learn and understand HTML tags, enabling them to create accessible and well-structured web content.",
    data: htmlTagTips,
  },
  {
    id: 5,
    route: "css",
    title: "CSS",
    description:
      "Helps create visually appealing and responsive web designs with animations, layouts, and styling. Improves user experience and adds a professional touch to web projects.",
    data: cssTips,
  },
];

const pageIds = {
  about: "about",
  portfolio: "portfolio",
  contact: "contact",
};

export {
  meta,
  dataabout,
  dataportfolio,
  worktimeline,
  oldworktimeline,
  skills,
  services,
  introdata,
  contactConfig,
  socialprofils,
  logotext,
  notes,
  pageIds,
};
